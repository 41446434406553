<template>
  <span class="icon" v-bind:class="`icon--${icon}`">
    <svg v-if="icon === 'accept'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.48 7.4a1.5 1.5 0 0 1 2.148 2.09l-.107.11-14 13a1.5 1.5 0 0 1-1.97.061l-.112-.1-5-5a1.5 1.5 0 0 1 2.008-2.225l.114.103 3.977 3.978L23.479 7.401z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'add'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 2a3 3 0 0 1 3 3v7h7a3 3 0 0 1 0 6h-7v7a3 3 0 0 1-6 0v-7H5a3 3 0 0 1 0-6h7V5a3 3 0 0 1 3-3z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'apple'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.027 4.546C19.94 3.366 20.632 1.7 20.382 0c-1.493.104-3.237 1.059-4.255 2.303-.928 1.128-1.69 2.806-1.393 4.434 1.632.051 3.316-.927 4.293-2.191zm8.065 15.997c-.653 1.456-.968 2.106-1.809 3.395-1.173 1.8-2.828 4.04-4.88 4.057-1.821.02-2.29-1.194-4.764-1.179-2.472.013-2.988 1.202-4.813 1.184-2.05-.018-3.618-2.04-4.791-3.84-3.283-5.028-3.629-10.93-1.604-14.07 1.44-2.229 3.711-3.533 5.845-3.533 2.172 0 3.539 1.199 5.337 1.199 1.745 0 2.807-1.202 5.319-1.202 1.901 0 3.916 1.042 5.35 2.84-4.7 2.592-3.94 9.343.81 11.15z" fill="#FFF" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'arrow'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.548 8.427l.104.113 5 6 .02.025c.018.021.034.043.05.065l-.07-.09a1.508 1.508 0 0 1 .225.364l.012.028a1.492 1.492 0 0 1-.084 1.308.987.987 0 0 1-.047.077l-.013.02-.023.033a1.506 1.506 0 0 1-.05.065l-.02.025-5 6a1.5 1.5 0 0 1-2.397-1.798l.093-.122L17.297 17H9.5a1.5 1.5 0 0 1-.144-2.993L9.5 14h7.796l-2.948-3.54a1.5 1.5 0 0 1 2.2-2.033z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'back'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.96 6.161a1.5 1.5 0 0 1 0 2.121L11.243 15l6.717 6.719a1.5 1.5 0 1 1-2.121 2.12l-7.763-7.762a1.5 1.5 0 0 1-.015-2.136l7.778-7.779a1.5 1.5 0 0 1 2.121 0z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'balance'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.755 25.36c4.38 0 6.93-2.43 8.34-4.92l-3.66-1.77c-.84 1.62-2.64 2.91-4.68 2.91-2.52 0-4.53-1.29-5.55-3.33h7.35v-2.37h-8.07c-.03-.27-.03-.57-.03-.87 0-.33 0-.63.06-.96h8.04v-2.37h-7.32c1.05-1.98 3.06-3.24 5.52-3.24 2.04 0 3.84 1.29 4.68 2.91l3.66-1.8c-1.41-2.52-3.96-4.89-8.34-4.89-4.89 0-8.85 2.73-10.2 7.02h-1.56v2.37h1.11c-.03.33-.03.63-.03.96 0 .3 0 .6.03.87h-1.11v2.37h1.53c1.32 4.35 5.31 7.11 10.23 7.11z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'coin'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 3c6.627 0 12 5.373 12 12s-5.373 12-12 12S3 21.627 3 15 8.373 3 15 3zm0 2.5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zM15 9a6 6 0 1 1 0 12 6 6 0 0 1 0-12z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'conflict'" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path d="M15.664 6.496l10.263 15.395A2 2 0 0 1 24.263 25H3.737a2 2 0 0 1-1.664-3.11L12.336 6.497a2 2 0 0 1 3.328 0z" fill="#FF1853" />
        <path d="M14.75 18l.25-6h-2l.26 6h1.49zm-.745 4c.546 0 .995-.46.995-1.005 0-.535-.45-.995-.995-.995-.545 0-1.005.46-1.005.995 0 .545.46 1.005 1.005 1.005z" fill="#FFF" fill-rule="nonzero" />
      </g>
    </svg>
    <svg v-if="icon === 'cross'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.132 7.575a1.5 1.5 0 0 1 0 2.122l-5.657 5.657 5.303 5.303a1.5 1.5 0 0 1-2.121 2.121l-5.303-5.303-5.657 5.657a1.5 1.5 0 0 1-2.122-2.122l5.657-5.656-6.01-6.01a1.5 1.5 0 0 1 2.121-2.122l6.01 6.01 5.657-5.657a1.5 1.5 0 0 1 2.122 0z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'edit'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.35 22.411a.7.7 0 0 1-.338 1.177l-4.236.976a.7.7 0 0 1-.84-.84l.976-4.236a.7.7 0 0 1 1.177-.338l3.261 3.261zm12.49-15.75a2.975 2.975 0 0 1 0 4.207L13.426 21.282a.84.84 0 0 1-1.188 0l-3.02-3.02a.84.84 0 0 1 0-1.187L19.633 6.66a2.975 2.975 0 0 1 4.207 0z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'expand'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.72 11.464a2 2 0 0 1 2.682 2.962l-.122.11-6 5a2 2 0 0 1-2.426.103l-.134-.103-6-5a2 2 0 0 1 2.43-3.172l.13.1L15 15.396l4.72-3.932z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'eye-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 5.75c3.335 0 6.235 1.676 8.687 4.416a20.65 20.65 0 0 1 2.764 3.947l.177.349c.163.34.163.736 0 1.076-.107.225-.285.563-.534.985l-.23.383a20.65 20.65 0 0 1-2.177 2.928c-2.452 2.74-5.352 4.416-8.687 4.416-3.335 0-6.235-1.676-8.687-4.416a20.65 20.65 0 0 1-2.764-3.947l-.177-.349a1.25 1.25 0 0 1 0-1.076c.107-.225.285-.563.534-.985l.23-.383a20.65 20.65 0 0 1 2.177-2.928C8.765 7.426 11.665 5.75 15 5.75zm0 2.5c-2.511 0-4.802 1.324-6.824 3.584a18.177 18.177 0 0 0-2.174 3.013L5.915 15l.149.26.201.335c.541.878 1.18 1.757 1.91 2.572 2.023 2.26 4.314 3.584 6.825 3.584s4.802-1.324 6.824-3.584a18.177 18.177 0 0 0 2.174-3.013l.086-.154-.148-.259-.201-.334a18.177 18.177 0 0 0-1.91-2.572C19.801 9.574 17.51 8.25 15 8.25zM15 11a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 2.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'eye-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 5.75c3.335 0 6.235 1.676 8.687 4.416a20.65 20.65 0 0 1 2.764 3.947l.177.349c.163.34.163.736 0 1.076-.107.225-.285.563-.534.985l-.23.383a20.65 20.65 0 0 1-2.177 2.928c-2.452 2.74-5.352 4.416-8.687 4.416-3.335 0-6.235-1.676-8.687-4.416a20.65 20.65 0 0 1-2.764-3.947l-.177-.349a1.25 1.25 0 0 1 0-1.076c.107-.225.285-.563.534-.985l.23-.383a20.65 20.65 0 0 1 2.177-2.928C8.765 7.426 11.665 5.75 15 5.75zM15 11a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 2.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
    </svg>
    <svg v-if="icon === 'facebook'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="M0 0h29.998v30H0z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="b" fill="#fff">
          <use xlink:href="#a" />
        </mask>
        <path d="M30 15.092C30 6.757 23.284 0 15 0 6.716 0 0 6.757 0 15.092 0 22.624 5.485 28.868 12.656 30V19.454H8.848v-4.362h3.808v-3.325c0-3.783 2.24-5.872 5.666-5.872 1.641 0 3.358.295 3.358.295v3.714h-1.892c-1.863 0-2.444 1.163-2.444 2.357v2.83h4.16l-.665 4.363h-3.495V30C24.514 28.868 30 22.624 30 15.092" fill="#FFFFFE" mask="url(#b)" />
      </g>
    </svg>
    <svg v-if="icon === 'google'" fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.5,0,0,1.5,-14.7,-15)" clip-path="url(#clip0_710_6221)">
        <path d="m29.6 20.227c0-0.7091-0.0636-1.3909-0.1818-2.0455h-9.4182v3.8682h5.3818c-0.2318 1.25-0.9363 2.3091-1.9954 3.0182v2.5091h3.2318c1.8909-1.7409 2.9818-4.3046 2.9818-7.35z" fill="#4285f4" />
        <path d="m20 30c2.7 0 4.9636-0.8955 6.6181-2.4227l-3.2318-2.5091c-0.8954 0.6-2.0409 0.9545-3.3863 0.9545-2.6046 0-4.8091-1.7591-5.5955-4.1227h-3.3409v2.5909c1.6455 3.2682 5.0273 5.5091 8.9364 5.5091z" fill="#34a853" />
        <path d="m14.404 21.9c-0.2-0.6-0.3136-1.2409-0.3136-1.9s0.1136-1.3 0.3136-1.9v-2.5909h-3.3409c-0.6772 1.35-1.0636 2.8773-1.0636 4.4909s0.3864 3.1409 1.0636 4.4909z" fill="#fbbc04" />
        <path d="m20 13.977c1.4681 0 2.7863 0.5045 3.8227 1.4954l2.8682-2.8682c-1.7318-1.6136-3.9955-2.6045-6.6909-2.6045-3.9091 0-7.2909 2.2409-8.9364 5.5091l3.3409 2.5909c0.7864-2.3636 2.9909-4.1227 5.5955-4.1227z" fill="#e94235" />
      </g>
      <defs>
        <clipPath id="clip0_710_6221">
          <rect transform="translate(10,10)" width="20" height="20" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
    <svg v-if="icon === 'filter'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.252 4l-.12.005c-.986.09-1.495 1.27-.834 2.052l9.104 10.766v7.227c0 .473.268.906.691 1.118l4.7 2.35.11.049a1.25 1.25 0 0 0 1.7-1.167v-9.578l9.104-10.765c.687-.812.11-2.057-.955-2.057h-23.5zm20.805 2.5l-7.66 9.058-.087.118a1.25 1.25 0 0 0-.208.69v8.011l-2.2-1.1v-6.912l-.008-.146a1.25 1.25 0 0 0-.287-.66L5.947 6.5h18.11z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'help'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.936 21.189c-1.064 0-1.857.73-1.93 1.726l-.006.151v.056c0 1.073.819 1.878 1.936 1.878 1.064 0 1.857-.73 1.93-1.726l.006-.208c0-1.072-.819-1.877-1.936-1.877zM15.052 6c-2.207 0-4.001.652-5.504 1.898A1.454 1.454 0 0 0 9 9.024c0 .787.672 1.459 1.46 1.459.324 0 .67-.13.96-.348 1.1-.824 2.241-1.219 3.548-1.219 1.81 0 2.986.854 3.071 2.315l.005.227c0 1.686-1.331 2.593-3.994 2.93-.594.074-.907.429-.869.986l.014.123.28 1.902c.075.51.433.883.898.937l.119.007h.224c.472 0 .869-.346.993-.83l.026-.123.14-.844.338-.068c3.277-.66 5.011-2.132 5.11-4.879l.005-.309c0-3.323-2.383-5.29-6.276-5.29z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'home-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.713 3.06l8.678 8.012A5 5 0 0 1 28 14.746V25a3 3 0 0 1-3 3h-5.5a2 2 0 0 1-2-2v-6.5a2.5 2.5 0 0 0-4.995-.164l-.005.164V26a2 2 0 0 1-2 2H5a3 3 0 0 1-3-3V14.746a5 5 0 0 1 1.609-3.674l8.678-8.011a4 4 0 0 1 5.426 0zm-1.696 1.838a1.5 1.5 0 0 0-1.916-.1l-.118.1-8.679 8.01a2.5 2.5 0 0 0-.797 1.648l-.007.19V25a.5.5 0 0 0 .41.492L5 25.5h5l.001-6.08.01-.246a5 5 0 0 1 9.984.11L20 19.5v6h5a.5.5 0 0 0 .492-.41L25.5 25V14.746a2.5 2.5 0 0 0-.67-1.704l-.134-.133-8.679-8.011z" fill="#A6B3C7" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'home-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 28a2 2 0 0 1-2-2v-6.5a2.5 2.5 0 0 0-4.995-.164l-.005.164V26a2 2 0 0 1-2 2H5a3 3 0 0 1-3-3V14.746a5 5 0 0 1 1.609-3.674l8.678-8.011a4 4 0 0 1 5.426 0l8.678 8.01A5 5 0 0 1 28 14.747V25a3 3 0 0 1-3 3h-5.5z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'invite'" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd" stroke="#000" stroke-width="2.5">
        <circle cx="17.5" cy="9" r="3.75" />
        <path d="M10 23v-2a4 4 0 0 1 4-4h7a4 4 0 0 1 4 4v2M6 10v6m-3-3h6" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>
    <svg v-if="icon === 'lock'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6a5 5 0 0 1 5 5v3a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2v-3a5 5 0 0 1 5-5zm5 10H10v6h10v-6zm-5-8a3 3 0 0 0-2.995 2.824L12 11v3h6v-3a3 3 0 0 0-3-3z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'marca'" viewBox="0 0 10 11" xmlns="http://www.w3.org/2000/svg">
      <path fill="#d62b1f" d="M0 8.59603H0.589286V2.33113H0V0H3.58791C4.0972 1.18204 4.33655 2.49413 4.92742 3.64238L6.29245 0H9.79688V2.33113H9.20759V8.59603H9.79688V11H6.04018V8.59603H6.70312V4.80795H6.64659L4.90751 9.32619C4.24305 7.87634 3.7897 6.31158 3.2041 4.81995L3.16741 8.59603H3.7567V11H0V8.59603Z" />
    </svg>
    <svg v-if="icon === 'market'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.46 13.348a1.5 1.5 0 0 1 .285 1.99l-.093.122L4.702 19H13.5a1.5 1.5 0 0 1 0 3H4.701l2.951 3.54a1.5 1.5 0 0 1-2.2 2.033l-.104-.113-3.933-4.72a3.5 3.5 0 0 1-.13-4.315l.13-.166 3.933-4.72a1.5 1.5 0 0 1 2.112-.191zM24.548 2.427l.104.113 3.933 4.72a3.5 3.5 0 0 1 .13 4.315l-.13.166-3.933 4.72a1.5 1.5 0 0 1-2.397-1.798l.093-.123 2.95-3.54H16.5a1.5 1.5 0 0 1 0-3h8.799l-2.951-3.54a1.5 1.5 0 0 1 2.2-2.033z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'more'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.25 20.5a1.75 1.75 0 0 1 0 3.5H3.75a1.75 1.75 0 0 1 0-3.5h22.5zm0-7.75a1.75 1.75 0 0 1 0 3.5H3.75a1.75 1.75 0 0 1 0-3.5h22.5zm0-7.75a1.75 1.75 0 0 1 0 3.5H3.75a1.75 1.75 0 0 1 0-3.5h22.5z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'new-post'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 2a1.25 1.25 0 0 1 .128 2.494l-.128.006h-5.5a2.75 2.75 0 0 0-2.745 2.582L4.5 7.25v15.5a2.75 2.75 0 0 0 2.582 2.745l.168.005h15.5a2.75 2.75 0 0 0 2.745-2.582l.005-.168v-5.66a1.25 1.25 0 0 1 2.494-.128l.006.128v5.66a5.25 5.25 0 0 1-5.034 5.246L22.75 28H7.25a5.25 5.25 0 0 1-5.246-5.034L2 22.75V7.25a5.25 5.25 0 0 1 5.034-5.246L7.25 2h5.5zm8.866 1.091a3.725 3.725 0 0 1 5.268 5.268L15.8 19.442c-.16.16-.361.274-.581.329l-4.667 1.167a1.25 1.25 0 0 1-1.516-1.516l1.167-4.667c.055-.22.169-.42.329-.58zm3.5 1.768a1.225 1.225 0 0 0-1.732 0L12.545 15.697l-.578 2.31 2.31-.578 10.84-10.838a1.225 1.225 0 0 0 .088-1.633z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'offers'" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 32 32;">
      <g>
        <path d="M23.7,9.3L20,5.6c1-0.8,2.4-0.7,3.4,0.2c0.5,0.5,0.8,1.2,0.8,1.9C24.2,8.2,24,8.8,23.7,9.3z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
        <path d="M18.4,14.9L5.5,28.6c-1,1-2.6,1-3.6,0s-1.1-2.7-0.1-3.7l12.9-13.7L18.4,14.9z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
        <polygon points="25.4,11 20.1,16.6 13,9.4 18.3,3.9" />
        <g>
          <g>
            <path d="M10.6,10L10.6,10c-0.5-0.5-0.5-1.3,0-1.8L17,1.4c0.5-0.5,1.4-0.5,1.9,0l0,0c0.5,0.5,0.5,1.3,0,1.8L12.5,10 C12,10.5,11.1,10.5,10.6,10z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
            <path d="M19.5,19.1L19.5,19.1c-0.5-0.5-0.5-1.3,0-1.8l6.4-6.8c0.5-0.5,1.4-0.5,1.9,0l0,0c0.5,0.5,0.5,1.3,0,1.8 L21.4,19C20.9,19.6,20.1,19.6,19.5,19.1z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
          </g>
          <g>
            <path d="M30.1,31H14.7c-0.4,0-0.8-0.4-0.8-0.8v-1.3c0-0.4,0.4-0.8,0.8-0.8h15.4c0.4,0,0.8,0.4,0.8,0.8v1.3 C30.9,30.6,30.6,31,30.1,31z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
            <path d="M29.4,28.1H15.5v-2c0-0.5,0.4-0.9,0.9-0.9h12.1c0.5,0,0.9,0.4,0.9,0.9V28.1z" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" />
          </g>
        </g>
      </g>
    </svg>
    <svg v-if="icon === 'pin'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.545 14.445l-10.99-10.99a1.554 1.554 0 0 0-2.653 1.1v3.751l-3.485 3.485H5.665a1.554 1.554 0 0 0-1.099 2.654l4.396 4.395-5.495 5.495a1.554 1.554 0 1 0 2.198 2.198l5.495-5.495 4.395 4.396a1.554 1.554 0 0 0 2.654-1.099v-3.752l3.485-3.485h3.752a1.554 1.554 0 0 0 1.099-2.653zm-5.495-.456c-.412 0-.808.164-1.099.456l-4.396 4.395a1.553 1.553 0 0 0-.455 1.1v.643L9.417 14.9h.644c.412 0 .807-.164 1.098-.455l4.396-4.396c.292-.292.456-.687.456-1.1v-.643l5.683 5.683h-.644z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'ranking-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.75 25.5a1.25 1.25 0 0 1 0 2.5h-9.5a1.25 1.25 0 0 1 0-2.5h9.5zM3.26 7.5l-.041.002a.5.5 0 0 0-.456.45v.09l.228 2.75a4.044 4.044 0 0 0 3.71 3.695l.024.058A8.97 8.97 0 0 1 6 11V7.499L3.26 7.5zm18.24-3h-13V11a6.504 6.504 0 0 0 5.107 6.35l.24.049 1.153.205 1.152-.205a6.503 6.503 0 0 0 5.343-6.15L21.5 11V4.5zM24 7.499V11c0 1.237-.25 2.415-.7 3.488l-.177.01a4.044 4.044 0 0 0 3.864-3.503l.022-.203.224-2.678.007-.104a.5.5 0 0 0-.373-.494l-.086-.014-.041-.002L24 7.499zM6 4.5A2.5 2.5 0 0 1 8.5 2h13A2.5 2.5 0 0 1 24 4.5v.499L26.74 5a3 3 0 0 1 2.999 3.073l-.01.176L29.5 11a6.544 6.544 0 0 1-6.29 5.996l-.23.004h-1.272a8.989 8.989 0 0 1-5.116 2.86l-.45 3.15a1.153 1.153 0 0 1-2.283 0l-.45-3.15a8.987 8.987 0 0 1-5.116-2.858L8.292 17H7.02A6.543 6.543 0 0 1 .5 11L.27 8.25A3 3 0 0 1 3.26 5L6 4.999V4.5z" fill="#A6B3C7" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'ranking-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.75 25.5a1.25 1.25 0 0 1 0 2.5h-9.5a1.25 1.25 0 0 1 0-2.5h9.5zM21.5 2A2.5 2.5 0 0 1 24 4.5v.499L27 5a3 3 0 0 1 .125.003l.124.007a3 3 0 0 1 2.74 3.24L29.76 11a6.543 6.543 0 0 1-6.52 6h-1.532a8.987 8.987 0 0 1-5.116 2.86l-.45 3.15a1.153 1.153 0 0 1-2.283 0l-.45-3.15A8.987 8.987 0 0 1 8.291 17H7.02A6.543 6.543 0 0 1 .5 11L.27 8.25A3 3 0 0 1 3.26 5L6 4.999V4.5A2.5 2.5 0 0 1 8.5 2h13zM24 7.499V11a8.972 8.972 0 0 1-.705 3.499l.147-.004a4.044 4.044 0 0 0 3.827-3.703l.23-2.75-.001-.09a.5.5 0 0 0-.457-.45L27 7.5l-3-.001zm-18 0L3.26 7.5l-.041.002a.5.5 0 0 0-.456.45v.09l.228 2.75a4.044 4.044 0 0 0 3.71 3.695A8.98 8.98 0 0 1 6 11V7.499z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'remove'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 15a3 3 0 0 1 3-3h20a3 3 0 0 1 0 6H5a3 3 0 0 1-3-3z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'search'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 4c5.523 0 10 4.477 10 10a9.955 9.955 0 0 1-1.943 5.924l3.5 3.5a1.51 1.51 0 0 1-2.134 2.134l-3.499-3.5A9.955 9.955 0 0 1 14 24C8.477 24 4 19.523 4 14S8.477 4 14 4zm0 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'settings'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(3 3)" stroke="#A6B3C7" stroke-width="2.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="3" />
        <path d="M20.073 15.273a1.8 1.8 0 0 0 .36 1.985l.065.066a2.182 2.182 0 1 1-3.087 3.087l-.066-.066a1.8 1.8 0 0 0-1.985-.36 1.8 1.8 0 0 0-1.09 1.648v.185a2.182 2.182 0 0 1-4.365 0v-.098a1.8 1.8 0 0 0-1.178-1.647 1.8 1.8 0 0 0-1.985.36l-.066.065a2.182 2.182 0 1 1-3.087-3.087l.066-.066a1.8 1.8 0 0 0 .36-1.985 1.8 1.8 0 0 0-1.648-1.09h-.185a2.182 2.182 0 0 1 0-4.365h.098a1.8 1.8 0 0 0 1.647-1.178 1.8 1.8 0 0 0-.36-1.985l-.065-.066A2.182 2.182 0 1 1 6.589 3.59l.066.066a1.8 1.8 0 0 0 1.985.36h.087a1.8 1.8 0 0 0 1.091-1.648v-.185a2.182 2.182 0 0 1 4.364 0v.098a1.8 1.8 0 0 0 1.09 1.647 1.8 1.8 0 0 0 1.986-.36l.066-.065a2.182 2.182 0 1 1 3.087 3.087l-.066.066a1.8 1.8 0 0 0-.36 1.985v.087a1.8 1.8 0 0 0 1.648 1.091h.185a2.182 2.182 0 0 1 0 4.364h-.098a1.8 1.8 0 0 0-1.647 1.09z" />
      </g>
    </svg>
    <svg v-if="icon === 'shop-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.72 2a4 4 0 0 1 3.923 3.216l1.269 6.342c.046.233.074.467.084.698L29 12.5l-.008.23-.024.27a4.556 4.556 0 0 1-2.97 3.729L26 23a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5l-.002-6.272a4.557 4.557 0 0 1-2.934-3.516l-.03-.21a4.596 4.596 0 0 1 .054-1.444l1.269-6.342A4 4 0 0 1 6.279 2h17.442zm-2.387 13.697A4.483 4.483 0 0 1 18.167 17 4.486 4.486 0 0 1 15 15.697 4.483 4.483 0 0 1 11.833 17a4.486 4.486 0 0 1-3.167-1.303A4.488 4.488 0 0 1 6.5 16.889V23a2.5 2.5 0 0 0 2.336 2.495L9 25.5h12a2.5 2.5 0 0 0 2.495-2.336L23.5 23l-.002-6.112a4.492 4.492 0 0 1-2.165-1.191zM23.721 4.5H6.279a1.5 1.5 0 0 0-1.436 1.068l-.035.138-1.268 6.342a2.05 2.05 0 0 0-.035.267l-.004.198.006.13.022.153c.149.885.868 1.572 1.708 1.685l.16.015.14.002.084-.001a1.979 1.979 0 0 0 1.151-.454l.134-.121 1.76-1.745 1.76 1.744c.374.37.87.579 1.407.579.477 0 .923-.165 1.278-.461l.128-.117L15 12.177l1.76 1.744c.374.37.871.579 1.408.579a1.98 1.98 0 0 0 1.277-.461l.129-.117 1.76-1.745 1.76 1.744c.303.301.69.496 1.07.556l.163.017.147.004.118-.003.131-.013.13-.022c.82-.164 1.445-.804 1.601-1.539l.025-.148.018-.204.003-.106v-.097a2.06 2.06 0 0 0-.013-.159l-.026-.159-1.268-6.342a1.5 1.5 0 0 0-1.33-1.2l-.141-.006z" fill="#A6B3C7" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'shop-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.72 2a4 4 0 0 1 3.923 3.216l1.269 6.342c.062.311.09.622.089.928L29 12.5l-.003.146-.002.03a4.516 4.516 0 0 1-.006.109l.008-.139a4.515 4.515 0 0 1-.024.312l-.003.021-.002.021A4.556 4.556 0 0 1 26 16.73V23a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5l-.001-6.271a4.555 4.555 0 0 1-2.966-3.727l-.004-.036a4.567 4.567 0 0 1-.01-.108l.014.143a4.596 4.596 0 0 1 .055-1.443l1.269-6.342A4 4 0 0 1 6.279 2h17.442zm-2.387 13.697A4.483 4.483 0 0 1 18.167 17 4.486 4.486 0 0 1 15 15.697 4.483 4.483 0 0 1 11.833 17a4.486 4.486 0 0 1-3.167-1.303A4.488 4.488 0 0 1 6.5 16.889V23a2.5 2.5 0 0 0 2.336 2.495L9 25.5h12a2.5 2.5 0 0 0 2.495-2.336L23.5 23v-6.112a4.492 4.492 0 0 1-2.167-1.191z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'sort'" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 36h12v-4h-12v4zm0-24v4h36v-4h-36zm0 14h24v-4h-24v4z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
    <svg v-if="icon === 'star-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4c.389 0 .748.19.955.5l.063.107 3.127 5.969 6.89 1.01c.424.062.776.348.91.74.117.348.045.726-.187 1.01l-.095.1-4.96 4.705 1.134 6.589c.054.311-.037.63-.247.872a1.158 1.158 0 0 1-.85.393 1.176 1.176 0 0 1-.42-.065l-.13-.054L15 22.802l-6.19 3.074c-.383.192-.845.16-1.195-.084a1.074 1.074 0 0 1-.467-.927l.015-.135 1.133-6.59-4.96-4.703a1.058 1.058 0 0 1-.28-1.112c.12-.352.417-.619.785-.714l.125-.025 6.89-1.01 3.126-5.97A1.142 1.142 0 0 1 15 4zm2.521 8.864L15 8.05l-2.521 4.814-5.295.776 3.803 3.607-.896 5.2L15 20.011l4.908 2.436-.895-5.2 3.802-3.607-5.294-.776z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'star-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4c.389 0 .748.19.955.5l.063.107 3.127 5.969 6.89 1.01c.424.062.776.348.91.74.117.348.045.726-.187 1.01l-.095.1-4.96 4.705 1.134 6.589c.054.311-.037.63-.247.872a1.158 1.158 0 0 1-.85.393 1.176 1.176 0 0 1-.42-.065l-.13-.054L15 22.802l-6.19 3.074c-.383.192-.845.16-1.195-.084a1.074 1.074 0 0 1-.467-.927l.015-.135 1.133-6.59-4.96-4.703a1.058 1.058 0 0 1-.28-1.112c.12-.352.417-.619.785-.714l.125-.025 6.89-1.01 3.126-5.97A1.142 1.142 0 0 1 15 4z" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'subs'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path d="M11.483 16.64l5.289-7.486A2 2 0 0 0 15.138 6H4.773a2 2 0 0 0-1.655 3.123l5.077 7.486a2 2 0 0 0 3.288.031z" fill="#FF1853" />
        <path d="M18.517 13.36l-5.289 7.486A2 2 0 0 0 14.862 24h10.365a2 2 0 0 0 1.655-3.123l-5.077-7.486a2 2 0 0 0-3.288-.031z" fill="#35C577" />
      </g>
    </svg>
    <svg v-if="icon === 'team-off'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 2a5 5 0 0 1 5 5v16a5 5 0 0 1-5 5h-2.557a1.96 1.96 0 0 1-.443.05h-8c-.152 0-.3-.017-.443-.05H8a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h14zm2.5 14l-4.856.001a4.752 4.752 0 0 1-9.288 0L5.5 16v7a2.5 2.5 0 0 0 2.336 2.495L8 25.5h1v-2a2 2 0 0 1 1.85-1.995L11 21.5h8a2 2 0 0 1 2 2v2h1a2.5 2.5 0 0 0 2.495-2.336L24.5 23v-7zM19 23.5h-8v2h8v-2zm-4-11.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5zM9 4.5H8a2.5 2.5 0 0 0-2.495 2.336L5.5 7v7h4.856a4.752 4.752 0 0 1 9.288 0H24.5V7a2.5 2.5 0 0 0-2.336-2.495L22 4.5h-1v2a2 2 0 0 1-1.85 1.995L19 8.5h-8a2 2 0 0 1-2-2v-2zm10 0h-8v2h8v-2z" fill="#A6B3C7" fill-rule="evenodd" />
    </svg>
    <svg v-if="icon === 'team-on'" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 2a5 5 0 0 1 5 5v16a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h14zm-3 19.5h-8l-.15.005A2 2 0 0 0 9 23.5v1l.007.117A1 1 0 0 0 11 24.5v-1h8v1l.007.117A1 1 0 0 0 21 24.5v-1l-.005-.15A2 2 0 0 0 19 21.5zm-4-11.25A4.752 4.752 0 0 0 10.356 14H6.5a1 1 0 0 0 0 2l3.856.001a4.752 4.752 0 0 0 9.288 0L23.5 16a1 1 0 0 0 0-2h-3.856A4.752 4.752 0 0 0 15 10.25zm0 2a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zm5-7.75a1 1 0 0 0-.993.883L19 5.5v1h-8v-1l-.007-.117a1 1 0 0 0-1.986 0L9 5.5v1l.006.157a2 2 0 0 0 1.845 1.838L11 8.5h8l.157-.006a2 2 0 0 0 1.838-1.845L21 6.5v-1l-.007-.117A1 1 0 0 0 20 4.5z" fill-rule="evenodd" />
    </svg>
  </span>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      icon: {
        required: true,
        type: String
      }
    }
  }
</script>
