import { Names } from '@/services/settingNames';
import * as platform from "Common/platformDetector";

export function createGazzettaSettings() {
  const customSettings = {};

  // Assets and CDN URL
  customSettings[Names.IMAGES_CDN_URL] = 'https://app-cdn.forge.pub/file/cdn-gazzetta';
  // Assets and CDN URL (alternative bunny CDN)
  //customSettings[Names.IMAGES_CDN_URL] = 'https://forge-images.b-cdn.net/file/cdn-gazzetta';

  customSettings[Names.MISTER_URL] = 'https://fantacampionatomanager.gazzetta.it';
  customSettings[Names.IS_WHITE_LABEL] = true;
  customSettings[Names.WHITE_LABEL] = "gazzetta";
  customSettings[Names.BRAND_NAME] = 'Fantacampionato Manager';
  customSettings[Names.BRAND_LOGO_URL] =
    `${customSettings[Names.IMAGES_CDN_URL]}/logo-v2.png`;
  customSettings[Names.BRAND_FAVICON] =
    `${customSettings[Names.IMAGES_CDN_URL]}/favicon-v3.png`;

  customSettings[Names.AUTH_APPLE_CLIENT_ID]               = 'com.fantasygazzetta.app';
  customSettings[Names.AUTH_APPLE_ENDPOINT]                = '/auth/runa/apple';
  customSettings[Names.AUTH_APPLE_REDIRECT_URI]            = '/api2/auth/runa/apple';
  customSettings[Names.AUTH_APPLE_ENABLED]                 = true;
  customSettings[Names.AUTH_EMAIL_ENABLED]                 = true;
  customSettings[Names.AUTH_EMAIL_ENDPOINT]                = '/auth/runa/email';
  customSettings[Names.AUTH_EMAIL_RECOVER_ENDPOINT]        = '/auth/runa/email/recover';
  customSettings[Names.AUTH_EMAIL_RESET_ENDPOINT]          = '/auth/runa/email/reset';
  customSettings[Names.AUTH_FACEBOOK_ENABLED]              = true;
  customSettings[Names.AUTH_FACEBOOK_ENDPOINT]             = '/auth/runa/facebook';
  customSettings[Names.AUTH_FACEBOOK_REDIRECT_METHOD_NAME] = 'runa-facebook';
  customSettings[Names.AUTH_GOOGLE_CLIENT_ID]              = '327689276851-19ocaslakjo4sfsepi38ih8cnu1g2da4.apps.googleusercontent.com';
  customSettings[Names.AUTH_GOOGLE_ENABLED]                = true;
  customSettings[Names.AUTH_GOOGLE_ENDPOINT]               = '/auth/runa/google';
  customSettings[Names.AUTH_GOOGLE_REDIRECT_URI]           = '/api2/auth/runa/google';
  customSettings[Names.AUTH_REGISTER_SHOW_CONSENT]         = true;

  customSettings[Names.TERMS_AND_CONDITIONS_URL] =
    'https://fantacampionatomanager.helpscoutdocs.com/article/430-termini-e-condizioni';
  customSettings[Names.PRIVACY_POLICY_URL] =
    'https://www.gazzetta.it/privacy.shtml';

  customSettings[Names.SHOW_ADD_LEAGUE_CONTEST_CASH]  = false;
  customSettings[Names.SHOW_ADD_LEAGUE_OTHER_OPTIONS] = true;

  customSettings[Names.DEFAULT_THEME] = 'gazzetta';
  customSettings[Names.STATUS_BAR_COLOR] = '#000000';

  if (platform.detect() === 'ios' || platform.detect() === 'android') {
    customSettings[Names.AUTH_APPLE_ENABLED]    = true;
    customSettings[Names.AUTH_FACEBOOK_ENABLED] = true;
    customSettings[Names.AUTH_GOOGLE_ENABLED]   = true;
  }

  return customSettings;
}
