<template>
  <transition name="fade">
    <div v-if="showToast" class="alert">
      <div class="alert-box" v-bind:class="`alert-${color}`">
        <pre>
          {{ text }}
        </pre>
        <button class="btn btn-close" v-on:click="toggleToast()">
          <SvgIcon class="icon" icon="cross"></SvgIcon>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
  import SvgIcon from '@/components/SvgIcon.vue';

  export default {
    name: 'ToastComponent',
    components: {
      SvgIcon
    },
    props: {
      color: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      show: {
        type: Boolean,
      },
    },

    data() {
      return {
        showToast: false,
      };
    },

    watch: {
      show(val) {
        this.showToast = val;
      },
    },

    mounted() {
      this.showToast = this.show;
    },

    methods: {
      activateToast() {
        this.showToast = true;
      },
      deactivateToast() {
        this.showToast = false;
      },
      toggleToast() {
        this.showToast = !this.showToast;
      },
    },
  };
</script>
