import { Names } from '@/services/settingNames';
import * as platform from "Common/platformDetector";

export function createMarcaSettings() {
  const customSettings = {}

  // Assets and CDN URL
  customSettings[Names.IMAGES_CDN_URL] = 'https://cdn-fantasy.marca.com/file/cdn-marca';
  // Assets and CDN URL (alternative bunny CDN)
  //customSettings[Names.IMAGES_CDN_URL] = 'https://forge-images.b-cdn.net/file/cdn-marca';

  customSettings[Names.MISTER_URL] = 'https://fantasy.marca.com';
  customSettings[Names.IS_WHITE_LABEL] = true;
  customSettings[Names.WHITE_LABEL] = "marca";
  customSettings[Names.BRAND_NAME] = 'Movistar Fantasy MARCA';
  customSettings[Names.BRAND_LOGO_URL] =
    `${customSettings[Names.IMAGES_CDN_URL]}/logo-v2.png`;
  customSettings[Names.BRAND_FAVICON] =
    `${customSettings[Names.IMAGES_CDN_URL]}/favicon.png`;

  customSettings[Names.AUTH_APPLE_ENABLED]    = false;
  customSettings[Names.AUTH_EMAIL_ENABLED]    = false;
  customSettings[Names.AUTH_FACEBOOK_ENABLED] = false;
  customSettings[Names.AUTH_SINGLE_METHOD]    = true;
  customSettings[Names.AUTH_UE_ENABLED]       = true;
  customSettings[Names.AUTH_UE_LOGOUT_URL]    = 'https://seguro.marca.com/registro/v2/logout.html';

  customSettings[Names.TERMS_AND_CONDITIONS_URL] =
    'https://fantasy-marca.helpscoutdocs.com/article/410-terminos-y-condiciones';
  customSettings[Names.PRIVACY_POLICY_URL] =
    'https://fantasy-marca.helpscoutdocs.com/article/407-politica-de-privacidad';

  customSettings[Names.SHOW_ADD_LEAGUE_CONTEST_CASH] = false;
  customSettings[Names.SHOW_ADD_LEAGUE_OTHER_OPTIONS] = 'true';

  customSettings[Names.DEFAULT_THEME] = 'fm';
  customSettings[Names.STATUS_BAR_COLOR] = '#000000';
  customSettings[Names.SHOW_ADD_LEAGUE_CONTEST_CASH] = 'false';

  const disableMarcaLoginForIOs = false;

  if (platform.detect() === 'ios' && disableMarcaLoginForIOs) {
    customSettings[Names.AUTH_EMAIL_ENABLED] = true;
    customSettings[Names.AUTH_UE_ENABLED]    = false;
    customSettings[Names.AUTH_SINGLE_METHOD] = false;
  }

  return customSettings;
}
