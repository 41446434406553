function show(context, message, color, timeMs) {
  return new Promise((resolve) => {
    context.commit('setMessage', message);
    context.commit('setColor', color);
    context.commit('setOpen', true);

    setTimeout(() => {
      context.commit('setOpen', false);
      resolve();
    }, timeMs);
  });
}

const toastsStore = {
  namespaced: true,

  state: () => ({
    message: '',
    isOpen: false,
    color: 'green'
  }),

  mutations: {
    setMessage(state, message) {
      state.message = message;
    },

    setOpen(state, open) {
      state.isOpen = open;
    },

    setColor(state, color) {
      state.color = color;
    }
  },

  actions: {
    showSuccess(context, { message, timeMs = 4000 }) {
      return show(
        context,
        message,
        'green',
        timeMs
      );
    },

    showError(context, { message, timeMs = 4000 }) {
      return show(
        context,
        message,
        'red',
        timeMs
      );
    }
  }
};

export default toastsStore;
