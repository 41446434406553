<template>
  <div class="app" id="app" v-bind:class="'theme-' + getUiTheme() + ' brand-' + getWhiteLabel()">
    <template v-if="getUiTheme() == 'kl'">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@600;700&display=swap" rel="stylesheet">
    </template>
    <template v-else-if="getUiTheme() == 'fm'">
      <link href="/css/fonts/Clan-OT.css" rel="stylesheet">
      <link href="/css/fonts/Aardvark.css" rel="stylesheet">
    </template>
    <template v-else-if="getUiTheme() == 'gazzetta'">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;700&family=Roboto+Slab:wght@500;700&display=swap" rel="stylesheet">
    </template>
    <template v-else>
      <link href="/css/fonts/Proxima-Nova.css" rel="stylesheet">
    </template>
    <div class="debug" v-if="debug">
      {{ i18n.t('APP_DEBUG_MODE') }}
    </div>
    <router-view v-bind:key="$route.path" v-if="!loading"></router-view>

    <Toast v-bind:color="toastColor" v-bind:show="isToastOpen" v-bind:text="toastMessage"></Toast>
  </div>
</template>

<script>
  import * as platformDetector from 'Common/platformDetector';
  import bus from '@/components/bus.js';
  import http, {RequestError} from 'Common/http';
  import { Keys } from '@/services/settings';
  import { analytics } from '@/services/analytics'
  import Toast from '@/components/Toast.vue';
  import { mapState } from 'vuex';

  export default {
    components: { Toast },
    inject: [ '$settings', 'i18n' ],
    data() {
      return {
        debug: false,
        loading: true
      };
    },

    computed: {
      ...mapState("toasts", {
        isToastOpen: state => state.isOpen,
        toastColor: state => state.color,
        toastMessage: state => state.message
      })
    },

    async mounted() {
      const that = this;

      // Dispatch fetching actions in the background
      this.$store.dispatch("fetchCompetitions");

      bus.$on('auth:success', async function(args) {
        try {
          // This request forces the server to set authentication cookies
          await http.post('/auth/external/exchange-token', { token: args.token });

          await that.$store.dispatch('loadCurrentUser');
          that.loadUser();
        } catch (error) {
          console.error('Error trying to exchange the token: ', error)

          if (args.state.method !== 'email') {
            that.$router.push({ name: 'Auth' });
          }

          return;
        }

        if (args.state.register) {
          analytics.send('af_complete_registration', {
            af_registration_method: args.state.method,
            onboarding_version: 2,
          });

          that.$router.push({ name: 'Add-League' });
          return;
        }

        if (args.state.referer) {
          window.location.href = args.state.referer;
          return;
        }

        const hasAfterAuthRedirection = sessionStorage.getItem('redirect-after-auth') !== null;
        if (hasAfterAuthRedirection) {
          const redirect = JSON.parse(sessionStorage.getItem('redirect-after-auth'));
          sessionStorage.removeItem("redirect-after-auth")
          that.$router.push(redirect);

          return;
        }

        window.location.href = '/feed'
      });

      bus.$on('app:debug', function() {
        that.debug = true;
        that.$store.commit('setDebug', true);

        if (platformDetector.detect() === "android") {
          window.Debug.enableWebViewDebugging();
          console.log("Android Debug mode activated");
        }
      });

      try {
        await this.$store.dispatch('loadCurrentUser');

        this.loadUser();

        const that = this;
        document.addEventListener('auth:unauthorized', function() {
          that.logout();
          that.$router.push({ name: 'Intro' });
        }, false);
      } catch(error) {
        if (error instanceof RequestError && error.response.status === 401) {
          this.logout();
        }
      }

      if (typeof this.$route.query.coupon !== 'undefined') {
        sessionStorage.setItem('coupon', this.$route.query.coupon);
      }

      this.loading = false;
    },

    methods: {
      loadUser() {
        this.i18n.changeLanguage(this.$store.state.user.lang);
        this.$forceUpdate();
      },

      logout() {
        this.$store.commit('removeUser');
        this.$store.commit('refreshAuth');
      },

      getWhiteLabel() {
        return this.$settings.get(Keys.WHITE_LABEL);
      },
      getUiTheme() {
        return this.$store.state.theme || this.$settings.get(Keys.DEFAULT_THEME) || 'light';
      },
    }
  };
</script>
